import React from 'react';
import BadgeLabel from 'components/BadgeLabel';
import { BADGES } from 'components/BadgeLabel/constants';
import { BylineList } from 'components/Byline';
import ContentTimestamp from 'components/ContentTimestamp';
import { Headline } from 'components/packages/Storyline/Headline';
import { StorylineMedia } from 'components/packages/Storyline/StorylineMedia';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { shouldTargetBeBlankHelper } from 'lib/shouldTargetBeBlankHelper';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import './styles.themed.scss';
import classNames from 'classnames';
import { RelatedContentTease } from 'components/RelatedContentTease';

export function SmallLayout({ content }) {
  const { 'show-nbc-sports-front': sportsFeatureFlag } = useFeatureFlagContext();

  if (!content || !content.items || !content.items.length) return null;

  const { items, metadata: packageMetadata } = content;

  // standard vs important storyType changes the headline size and order of components on the page
  const storyTypes = {
    IMPORTANT: 'important',
    STANDARD: 'standard',
  };
  const storyType = packageMetadata?.layoutType || storyTypes.STANDARD;

  const mainContentItem = items[0];

  const {
    computedValues,
    item,
    metadata,
  } = mainContentItem || {};

  let {
    related,
  } = mainContentItem || {};

  if (Array.isArray(related) && related.length > 0) {
  // filter over related content to ensure that no null values are passed through
    related = related.filter(Boolean);
  }

  const hasRelatedContent = Array.isArray(related) && related.length > 0;
  const authors = item?.authors ?? [];

  const sourceName = item?.source?.name || item?.source?.organization?.name;
  const subType = item?.subType;
  const shouldOpenLinkInNewTab = sportsFeatureFlag ? shouldTargetBeBlankHelper(sourceName, subType) : false;
  const moveArticleInfo = mainContentItem?.metadata?.moveArticleInfo ? mainContentItem?.metadata?.moveArticleInfo : null;

  const {
    dek,
    headline: computedHeadline,
    unibrow,
    url,
  } = computedValues || {};

  const {
    headlineSize = 'large',
    mediaSize = 'medium',
  } = packageMetadata;

  const {
    badge,
    hasBadge,
    hasEyebrow = true,
    badgeText,
    showAuthor,
    showDek,
    showTimestamp,
  } = metadata || {};

  const headline = computedHeadline || item?.headline?.primary;
  const headlineType = headlineSize || 'standard';
  const showHeadlineOnly = !showAuthor && !showDek && !showTimestamp;
  const showMedia = mediaSize !== 'none';
  const delimiter = ' / ';
  const getBadgeType = () => {
    if (hasBadge && !badge) {
      return 'BREAKING';
    }
    return (hasBadge && badge) || BADGES.EYEBROW;
  };

  /**
   * dekAuthorTimestamp
   * @returns {JSX.Element}
   * render dek author and timestamp
   */
  const dekAuthorTimestamp = () => (
    <>
      {showDek && (
        <div
          data-testid="storyline-small-dek"
          className={classNames(
            'dek',
            {
              'important-dek-no-author': storyType === storyTypes.IMPORTANT && !showAuthor && !showTimestamp,
            },
        )}
        >
          {dek}
        </div>
      )}
      <div data-testid="storyline-small-author" className="author">
        {showAuthor && authors.length > 0 && (
          <BylineList
            authors={authors}
          />
        )}
        {showAuthor && authors.length > 0 && showTimestamp && (
          <span data-testid="storyline-small-delim" className="deliminator">
            {delimiter}
          </span>
        )}

        {showTimestamp && (
          <span className="timestamp" data-testid="storyline-small-timestamp">
            <ContentTimestamp
              dateCreated={item?.dateCreated}
              datePublished={item?.datePublished}
              delimiter=", "
            />
          </span>

        )}
      </div>
    </>
  );

  /**
   * headlineComponent
   * @returns {JSX.Element}
   */
  const headlineComponent = () => (
    <div className={classNames(
      'headline',
      {
        'headline-center': storyType === storyTypes.IMPORTANT && headlineType === 'large',
      },
    )}
    >
      <div className="standard-layout__badge">
        {hasEyebrow && (
          <BadgeLabel
            badgeType={getBadgeType()}
            badgeText={badgeText}
            eyebrowText={unibrow?.text}
            url={unibrow?.url?.primary}
          />
        )}
      </div>
      <Headline
        text={headline}
        headlineSize={headlineType}
        storyType={storyType}
        url={(items[0].type === 'video' && metadata.headlineUrlOverride) ? metadata.headlineUrlOverride : url}
        showHeadlineOnly={showHeadlineOnly}
        shouldOpenLinkInNewTab={shouldOpenLinkInNewTab}
      />
      <div className={classNames(
          { hidePositionStandard: moveArticleInfo },
        )}
      >
        {dekAuthorTimestamp()}
      </div>
    </div>
  );

  const standard = () => (
    <>
      <div className={classNames(
        'main',
        {
          'no-relateds': !hasRelatedContent,
        },
      )}
      >
        {showMedia && (
          <StorylineMedia shouldOpenLinkInNewTab={shouldOpenLinkInNewTab} contentItem={mainContentItem} packageMetadata={packageMetadata} />
        )}
        {headlineComponent()}
      </div>
      {hasRelatedContent && (
        <div className="related" data-testid="storyline-small-related">
          {
            related.map((relatedItem, i) => {
              const itemSourceName = relatedItem?.item?.source?.name || relatedItem?.item?.source?.organization?.name;
              const itemSubType = relatedItem?.item?.subType;
              const shouldOpenItemLinkInNewTab = sportsFeatureFlag ? shouldTargetBeBlankHelper(itemSourceName, itemSubType) : false;

              return (
                <div key={relatedItem.id}>
                  {i > 0 && (<hr className="related-border" />)}
                  <RelatedContentTease shouldOpenLinkInNewTab={shouldOpenItemLinkInNewTab} relatedItem={relatedItem} lastRelated={related.length - 1 === i} />
                </div>
              );
            })
          }
        </div>
      )}
    </>
  );

  const important = () => (
    <>
      {headlineComponent()}
      <div className="main">
        {showMedia && (
          <StorylineMedia shouldOpenLinkInNewTab={shouldOpenLinkInNewTab} contentItem={mainContentItem} packageMetadata={packageMetadata} />
        )}
        <div className="related-container">
          <div className={classNames(
            'articleInfoContainerMoved',
            { hidePositionMoved: !moveArticleInfo },
          )}
          >
            {dekAuthorTimestamp()}
          </div>
          {hasRelatedContent && (
            <div className={content.metadata.mediaSize === 'small' ? 'related' : 'related-no-media'} data-testid="storyline-small-related">
              {
                related.map((relatedItem, i) => (
                  <div key={relatedItem.id}>
                    {i > 0 && (<hr className="related-border" />)}
                    <RelatedContentTease shouldOpenLinkInNewTab={shouldOpenLinkInNewTab} relatedItem={relatedItem} lastRelated={related.length - 1 === i} />
                  </div>
                ))
              }
            </div>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div
      className={classNames(
        'small-storyline',
        {
          important: storyType === storyTypes.IMPORTANT,
          standard: storyType === storyTypes.STANDARD,
        },
      )}
      data-testid="storyline-small-layout"
    >

      {storyType === storyTypes.STANDARD ? standard() : important()}

      {/* <div className="liveblog" /> */}
    </div>
  );
}

SmallLayout.propTypes = {
  content: PackagePropType.isRequired,
};
